import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { upperFirst, upperCase } from 'lodash'
import {
  FACEBOOK_LANDING_PAGE,
  QUIZ_LANDING_PAGE,
  QUIZ_RESULT_PAGE,
  OFFER_PAGE
} from '../../../shared/constants/templateConstants'
import OfferPageProvider from '../context/OfferPageProvider'

const LoadTemplateSectionComponents = async (template, section) => {
  let stepLayout

  switch (upperCase(template)) {
  case FACEBOOK_LANDING_PAGE:
    stepLayout = await import('../sections/FLP')
    break
  case QUIZ_LANDING_PAGE:
    stepLayout = await import('../sections/FQLP')
    break
  case QUIZ_RESULT_PAGE:
    stepLayout = await import('../sections/FQRP')
    break
  case OFFER_PAGE:
    stepLayout = await import('../sections/FOP')
    break
  default:
    stepLayout = await import('../sections')
  }

  if (template && !stepLayout[upperFirst(section._type)]) {
    // Is there no sections for a template get the default ones
    stepLayout = await import('../sections')
  }

  return stepLayout[upperFirst(section._type)]
}

const SectionComponent = ({ section, template }) => {
  // eslint-disable-next-line import/namespace
  const [SectionComp, setSectionComp] = useState(undefined)

  useEffect(() => {
    const getSection = async () => {
      try {
        const result = await LoadTemplateSectionComponents(template, section)
        if (result) {
          setSectionComp(() => result)
        } else {
          setSectionComp(() => null)
        }
      } catch {
        setSectionComp(() => null)
      }
    }
    getSection()
  }, [section])

  if (SectionComp === undefined) {
    return ''
  }

  if (SectionComp) {
    return <SectionComp {...section} key={section._key} />
  }

  console.error('Cant find section', section._type) // eslint-disable-line no-console
  return ''
}

const RenderSections = props => {
  const { sections, template } = props

  if (!sections) {
    console.error('Missing section')
    return <div>Missing sections</div>
  }

  if (upperCase(template) === OFFER_PAGE){
    return (
      <Fragment>
        <OfferPageProvider>
          {sections.map(section => {
            return <SectionComponent section={section} template={template} key={section._key} />
          })}
        </OfferPageProvider>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {sections.map(section => {
        return <SectionComponent section={section} template={template} key={section._key} />
      })}
    </Fragment>
  )
}

SectionComponent.propTypes = {
  section: PropTypes.object,
  template: PropTypes.string
}

RenderSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      _type: PropTypes.string,
      _key: PropTypes.string,
      section: PropTypes.instanceOf(PropTypes.object)
    })
  ),
  template: PropTypes.string
}

export default RenderSections
