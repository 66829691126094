import EmbedHTML from './EmbedHTML'
import Figure from './Figure'
import React from 'react'
import PropTypes from 'prop-types'
const serializers = {
  types: {
    embedHTML: EmbedHTML,
    figure: Figure
  },
  marks: {
    link: ({ mark, children }) => {
      const { blank, href } = mark
      return blank ? (
        <a href={href} target="_blank">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      )
    }
  }
}

serializers.marks.link.propTypes = {
  mark: PropTypes.object,
  children: PropTypes.node
}

export default serializers
