import React from 'react'
import PropTypes from 'prop-types'
import { imageUrlBuilder } from '../utils/sanity'

function Figure({ node }) {
  const { alt, caption, asset } = node
  if (!asset) {
    return undefined
  }
  return (
    <figure>
      <img
        src={imageUrlBuilder(asset)
          .auto('format')
          .width(2000)
          .url()}
        alt={alt}
      />
      {caption && (
        <figcaption>
          <div>
            <div>
              <p>{caption}</p>
            </div>
          </div>
        </figcaption>
      )}
    </figure>
  )
}

Figure.propTypes = {
  node: PropTypes.shape({
    alt: PropTypes.string,
    caption: PropTypes.string,
    asset: PropTypes.shape({
      _ref: PropTypes.string
    })
  })
}
export default Figure
