import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import { LogoJsonLd } from 'next-seo'
import Header from './Header'
import Footer from './Footer'
import uuid from 'react-uuid'
import { triggerPageView } from '../../lib/facebookConversion'

function Layout(props) {
  const { hideFooter, hideHeader, config, children } = props

  if (!config) {
    console.error('Missing config')
    return <div> Missing config </div>
  }

  const eventId = uuid()

  useEffect(() => {
    // Send eventId to GTM
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      eventId: eventId
    })
    triggerPageView(eventId)
  }, [])

  const { title, mainNavigation, footerNavigation, footerText, logo, url } = config
  const logoUrl = logo && logo.asset && logo.asset.url

  return (
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width, viewport-fit=cover" />
      </Head>
      {hideHeader ? <></> : <Header title={title} navItems={mainNavigation} logo={logo} />}
      {children}
      {hideFooter ? <></> : <Footer navItems={footerNavigation} text={footerText} />}
      {logoUrl && url && <LogoJsonLd url={url} logo={logoUrl} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  config: PropTypes.shape({
    title: PropTypes.string,
    mainNavigation: PropTypes.arrayOf(PropTypes.object),
    footerNavigation: PropTypes.arrayOf(PropTypes.object),
    footerText: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
      asset: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    url: PropTypes.string
  })
}

Layout.propTypes = {
  hideFooter: PropTypes.bool,
  hideHeader: PropTypes.bool
}

export default Layout
