import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { withRouter } from 'next/router'
import SimpleBlockContent from './SimpleBlockContent'

function Footer(props) {
  const { navItems, text, router } = props
  return (
    <div>
      <nav>
        <ul>
          {navItems &&
            navItems.map(item => {
              const isActive = router.pathname === '/' && router.query.slug === item.slug.current
              return (
                <li key={item._id}>
                  <Link
                    href={{
                      pathname: '/',
                      query: { slug: item.slug.current }
                    }}
                    as={`/${item.slug.current}`}
                  >
                    <a data-is-active={isActive ? 'true' : 'false'}>{item.title}</a>
                  </Link>
                </li>
              )
            })}
        </ul>
      </nav>
      <div>
        <SimpleBlockContent blocks={text} />
      </div>
    </div>
  )
}

Footer.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string
      }).isRequired
    })
  ),
  text: PropTypes.arrayOf(PropTypes.object),
  router: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      slug: PropTypes.string
    })
  })
}

export default withRouter(Footer)
