import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NextSeo } from 'next-seo'
import Layout from './Layout'
import RenderSections from './RenderSections'
import { imageUrlBuilder } from '../utils/sanity'
import smoothscroll from 'smoothscroll-polyfill'
import 'smoothscroll-anchor-polyfill'
import { getGoogleClientId } from '../utils/getGoogleClientId'

const LandingPage = ({ page = {}, config = {}, hideFooter = false, hideHeader = false }) => {
  const { content = [], title, openGraphImage, disallowRobots, template, slug } = page

  useEffect(() => {
    getGoogleClientId().then(googleClientId =>
      window.localStorage.setItem('googleClientId', googleClientId)
    )
    smoothscroll.polyfill()
  })
  const openGraphImages = openGraphImage
    ? [
      {
        url: imageUrlBuilder(openGraphImage)
          .width(800)
          .height(600)
          .url(),
        width: 800,
        height: 600,
        alt: title
      },
      {
        // Facebook recommended size
        url: imageUrlBuilder(openGraphImage)
          .width(1200)
          .height(630)
          .url(),
        width: 1200,
        height: 630,
        alt: title
      },
      {
        // Square 1:1
        url: imageUrlBuilder(openGraphImage)
          .width(600)
          .height(600)
          .url(),
        width: 600,
        height: 600,
        alt: title
      }
    ]
    : []

  return (
    <Layout config={config} hideFooter={hideFooter} hideHeader={hideHeader}>
      <NextSeo
        title={title}
        titleTemplate={`${config.title} | %s`}
        description={config.description}
        canonical={config.url && `${config.url}/${slug}`}
        openGraph={{ images: openGraphImages }}
        noindex={disallowRobots}
      />
      {content && <RenderSections sections={content} template={template} />}
    </Layout>
  )
}

LandingPage.propTypes = {
  page: PropTypes.object.isRequired,
  config: PropTypes.object,
  hideFooter: PropTypes.bool,
  hideHeader: PropTypes.bool
}

export default LandingPage
