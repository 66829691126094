import React from 'react'
import PropTypes from 'prop-types'
import BlockContent from '@sanity/block-content-to-react'
import { config } from '../utils/sanity'
import serializers from './serializers'
import { useTranslation } from 'react-i18next'

const { projectId, dataset } = config

function SimpleBlockContent(props) {
  const { blocks } = props
  const { i18n } = useTranslation()

  if (!blocks) {
    console.error('Missing blocks')
    return null
  }

  return (
    <BlockContent
      blocks={blocks[i18n.language] || blocks}
      serializers={serializers}
      projectId={projectId}
      dataset={dataset}
    />
  )
}

SimpleBlockContent.propTypes = {
  blocks: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
}

export default SimpleBlockContent
