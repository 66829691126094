import axios from 'axios'
import publicIp from 'public-ip'
import ipLocation from 'iplocation'
import { getUA } from 'react-device-detect'
import { sha256 } from 'js-sha256'
import { isProduction } from '../src/utils/envUtils'

const ERROR_MESSAGE = 'Unable to track server event'

const getEventTimeStamp = () => {
  const dateTime = Date.now()
  return Math.floor(dateTime / 1000)
}

const sendEvent = request => {
  axios
    .post(process.env.NEXT_PUBLIC_CAPI_URL, request)
    .then()
    .catch(function(error) {
      return Promise.reject(error)
    })
}

export async function triggerPageView(eventId) {
  try {
    // To enable facebook conversion API on develop with test mode
    const enableDevelop = !isProduction() && process.env.NEXT_PUBLIC_FACEBOOK_ENABLE_FOR_TEST

    if (isProduction() || enableDevelop) {
      const ipAddress = await publicIp.v4()
      const geoLocation = await ipLocation(ipAddress)

      const requestBody = {
        data: [
          {
            event_id: eventId,
            event_name: 'PageView',
            event_time: getEventTimeStamp(),
            event_source_url: window.location.href,
            action_source: 'website',
            user_data: {
              country: sha256(Buffer.from(geoLocation.country.code.toLowerCase())),
              st: sha256(Buffer.from(geoLocation.region.code.toLowerCase())),
              ct: sha256(Buffer.from(geoLocation.city.toLowerCase())),
              client_ip_address: ipAddress,
              client_user_agent: getUA
            }
          }
        ]
      }

      if (enableDevelop) {
        requestBody['test_event_code'] = process.env.NEXT_PUBLIC_FACEBOOK_TEST_CODE
      }

      sendEvent(requestBody)
    }
  } catch (e) {
    console.log(ERROR_MESSAGE)
  }
}
