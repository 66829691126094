import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

export const productSelection = { selectedGift: null, addGiftToCart: null, selectedSystem: null, addSystemToCart:null, footerSelectedSystem:null, addFooterSystemToCart:null }

export const OfferPageContext = React.createContext(productSelection)

const OfferPageProvider = ({ children }) => {
  const [selectedGift, setSelectedGift] = useState(null)
  const [selectedSystem, setSelectedSystem] = useState(null)
  const [footerSelectedSystem, setFooterSystemToCart] = useState(null)

  const addGiftToCart = useCallback((giftId, giftHandle) => {
    setSelectedGift(() => {
      return {
        id: giftId,
        handle: giftHandle
      }
    })
  })

  const addSystemToCart = useCallback((systemId, systemHandler) => {
    setSelectedSystem(() => {
      return {
        id: systemId,
        handle: systemHandler
      }
    })
  })

  const addFooterSystemToCart = useCallback((systemId, systemHandler) => {
    setFooterSystemToCart(() => {
      return {
        id: systemId,
        handle: systemHandler
      }
    })
  })

  return (
    <OfferPageContext.Provider
      value={{ selectedGift, addGiftToCart, selectedSystem, addSystemToCart, footerSelectedSystem, addFooterSystemToCart }}
    >
      {children}
    </OfferPageContext.Provider>
  )
}

OfferPageProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
}

export default OfferPageProvider